import isEqual from 'lodash.isequal';
import React, { FC, useCallback, useEffect, useRef } from 'react';
import { FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Dropdown, Fade, formatNumber, Icon, Input, OptionGroup, OptionProps } from '@column/column-ui-kit';

import { EntryClassCodeDefinitions } from '../TypeContent/ach';
import { ROUTE } from '~/app/routes';
import { PageHeader, RenderFields } from '~/components';
import { LogoLoading } from '~/elements';
import { useBankAccounts } from '~/hooks';
import { useFormErrorHandler } from '~/hooks/useFormErrorHandler';
import {
  useCreateTransferTemplate,
  useGetTransferTemplate,
  useUpdateTransferTemplate,
} from '~/hooks/useTransferTemplates';
import {
  CreateTransferTemplateRequest,
  TransferTemplateResponse,
  TransferTemplateType,
  UpdateTransferTemplateRequest,
} from '~/repositories/Transfer/TransferTemplateRepository';
import { useModalStore } from '~/stores/Modal';
import { useNotificationStore } from '~/stores/Notification';
import { log, removeEmptyString } from '~/util';

import { TransferList } from './_partial/TransferList';

interface Params {
  id: string;
}

interface LocationState {
  defaultFormValues?: TransferTemplateResponse | null;
}

const Wrapper = styled.div`
  padding: 0 0 24px 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const Logo = styled(LogoLoading)`
  top: calc(50vh - 130px);
`;

const StyledOption = styled(OptionGroup.Option)<OptionProps>`
  opacity: 1;

  ${OptionGroup.Option.Label} {
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  }
`;

const OptionCheck = styled(Icon.CircleCheck)`
  --icon-size: 20px;
  --icon-color: ${({ theme }) => theme.primary.background};
  margin: -2px 0 -2px -2px;
`;

export const PageTransferTemplatesEdit: FC = () => {
  const { id } = useParams<keyof Params>() as Params;
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const navigate = useNavigate();
  const openModal = useModalStore((state) => state.openModal);
  const { handleFormErrors } = useFormErrorHandler();

  const location = useLocation();
  const locationState = location.state as LocationState;

  const initialValues = useRef<Partial<TransferTemplateResponse> | null>(null);

  const { response: bankAccounts, setQueryParams: searchBankAccounts } = useBankAccounts();

  const { createRequest: getTransferTemplate, isLoading } = useGetTransferTemplate({
    onError: (error) => {
      addDangerNotification({
        content: error.message,
      });

      navigate(`${ROUTE.TRANSFERS}/templates`);
    },
  });
  const { createRequest: createTransferTemplate } = useCreateTransferTemplate({
    onError: (error) => {
      addDangerNotification({
        content: error.message,
      });
    },
    onSuccess: () => {
      addSuccessNotification({
        content: 'Transfer template created successfully',
      });
    },
  });
  const { createRequest: updateTransferTemplate } = useUpdateTransferTemplate({
    onError: (error) => {
      addDangerNotification({
        content: error.message,
      });
    },
    onSuccess: () => {
      addSuccessNotification({
        content: 'Transfer template updated successfully',
      });
    },
  });

  const methods = useForm<TransferTemplateResponse>({
    mode: 'onChange',
    defaultValues: locationState?.defaultFormValues ?? {
      templateType: TransferTemplateType.ACH,
      achDefaultParameters: {},
      achTransfers: [],
      wireDefaultParameters: {},
      wireTransfers: [],
    },
  });

  const { handleSubmit, reset, watch, setValue, getValues } = methods;

  useEffect(() => {
    if (id && !locationState?.defaultFormValues) {
      getTransferTemplate({ transferTemplateId: id }).then((data) => {
        if (!data) {
          return;
        }

        const newTransferTemplate = { ...data } as Partial<TransferTemplateResponse>;

        delete newTransferTemplate?.version;

        reset(newTransferTemplate);

        initialValues.current = newTransferTemplate;
      });
    }
    if (locationState?.defaultFormValues) {
      reset(locationState.defaultFormValues);

      initialValues.current = locationState.defaultFormValues;
    }
  }, [id, locationState?.defaultFormValues]);

  const onSuccess = useCallback(
    async (transferTemplate: TransferTemplateResponse) => {
      const newTransferTemplate = removeEmptyString<Partial<TransferTemplateResponse>>(transferTemplate);

      if (watch('templateType') === TransferTemplateType.ACH) {
        if ((watch('achTransfers') ?? []).length <= 0) {
          addDangerNotification({
            content: 'Please add at least one ACH transfer',
          });

          return;
        }

        delete newTransferTemplate?.wireTransfers;
        delete newTransferTemplate?.wireDefaultParameters;
      }

      if (watch('templateType') === TransferTemplateType.Wire) {
        if ((watch('wireTransfers') ?? []).length <= 0) {
          addDangerNotification({
            content: 'Please add at least one Wire transfer',
          });

          return;
        }

        delete newTransferTemplate?.achTransfers;
        delete newTransferTemplate?.achDefaultParameters;
      }

      newTransferTemplate.achTransfers?.forEach((transfer) => {
        delete transfer?.bankAccountDescription;
        delete transfer?.counterpartyDescription;
      });

      newTransferTemplate.wireTransfers?.forEach((transfer) => {
        delete transfer?.bankAccountDescription;
        delete transfer?.counterpartyDescription;
      });

      if (id) {
        const updateTransferTemplateRequest = {
          ...newTransferTemplate,
        } as Partial<TransferTemplateResponse>;

        delete updateTransferTemplateRequest?.templateType;
        delete updateTransferTemplateRequest?.lastUsedAt;
        delete updateTransferTemplateRequest?.usageCount;
        delete updateTransferTemplateRequest?.achDefaultParameters?.bankAccountDescription;
        delete updateTransferTemplateRequest?.achDefaultParameters?.counterpartyDescription;
        delete updateTransferTemplateRequest?.wireDefaultParameters?.bankAccountDescription;
        delete updateTransferTemplateRequest?.wireDefaultParameters?.counterpartyDescription;

        updateTransferTemplateRequest.achTransfers?.forEach((transfer) => {
          delete transfer?.bankAccountDescription;
          delete transfer?.counterpartyDescription;
        });

        updateTransferTemplateRequest.wireTransfers?.forEach((transfer) => {
          delete transfer?.bankAccountDescription;
          delete transfer?.counterpartyDescription;
        });

        const newData = await updateTransferTemplate({
          ...updateTransferTemplateRequest,
          transferTemplateId: id,
        } as UpdateTransferTemplateRequest);

        if (!newData) {
          return;
        }

        reset(newData);

        return;
      }

      const data = await createTransferTemplate(newTransferTemplate as CreateTransferTemplateRequest);

      if (!data) {
        return;
      }

      return;
    },
    [id, watch('templateType'), watch('achTransfers'), watch('wireTransfers')]
  );

  const onError: SubmitHandler<FieldValues> = useCallback((errors) => {
    handleFormErrors({ errors });
  }, []);

  const handleCancelClick = useCallback(() => {
    reset();

    navigate(`${ROUTE.TRANSFERS}/templates`, { state: { defaultFormValues: null } });
  }, []);

  const handleReviewClick = () => {
    const initial = removeEmptyString(initialValues.current ?? {});
    const current = removeEmptyString(watch());

    const normalizeTransfers = (obj: any) => {
      const normalized = JSON.parse(JSON.stringify(obj));

      if (normalized.wireTransfers) {
        normalized.wireTransfers = normalized.wireTransfers.map((transfer: any) => ({
          ...transfer,
          amount: String(transfer.amount),
          bankAccountDescription: undefined,
          counterpartyDescription: undefined,
        }));
      }

      if (normalized.achTransfers) {
        normalized.achTransfers = normalized.achTransfers.map((transfer: any) => ({
          ...transfer,
          amount: String(transfer.amount),
          bankAccountDescription: undefined,
          counterpartyDescription: undefined,
        }));
      }

      return normalized;
    };

    const cleanInitial = normalizeTransfers(initial);
    const cleanCurrent = normalizeTransfers(current);

    const hasChanges = !isEqual(cleanInitial, cleanCurrent);

    log({ type: 'info', name: 'ReviewTransferTemplate', context: { hasChanges, cleanInitial, cleanCurrent } });

    if (!hasChanges) {
      navigate(`${ROUTE.TRANSFERS}/templates/review/${id}`, { state: { defaultFormValues: watch() } });

      return;
    }

    openModal('ReviewTransferTemplate', {
      onReview: () => {
        navigate(`${ROUTE.TRANSFERS}/templates/review/${id}`, { state: { defaultFormValues: watch() } });
      },
      onSaveReview: () => {
        onSuccess(getValues()).then(() => {
          navigate(`${ROUTE.TRANSFERS}/templates/review/${id}`, { state: { defaultFormValues: null } });
        });
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSuccess, onError)}>
        <PageHeader text={id ? 'Edit Transfer Template' : 'Create Transfer Template'}>
          <Button type="button" variant="secondary" size="small" onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button type="submit" size="small">
            {id ? 'Save Template' : 'Create Template'}
          </Button>
          {id && (
            <Button type="button" size="small" onClick={handleReviewClick}>
              Review
            </Button>
          )}
        </PageHeader>

        <Fade show={!!isLoading && !!id && !locationState?.defaultFormValues}>
          <Logo />
        </Fade>
        <Fade show={!isLoading || !id || !!locationState?.defaultFormValues}>
          <Wrapper>
            {RenderFields<TransferTemplateResponse>({
              sections: [
                {
                  fields: [
                    {
                      id: 'description',
                      label: 'Template Name',
                      defaultValue: '',
                      rules: {
                        required: true,
                      },
                      children: ({ value, onChange, onBlur }, { isTouched, error }) => (
                        <Input
                          placeholder="Template Name"
                          hasError={isTouched && !!error}
                          value={String(value)}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      ),
                    },
                    {
                      id: 'templateType',
                      label: 'Template Type',
                      defaultValue: TransferTemplateType.ACH,
                      children: ({ value, onChange }) => (
                        <OptionGroup orientation={OptionGroup.Orientation.Horizontal}>
                          {((value === TransferTemplateType.ACH && id) || !id) && (
                            <StyledOption
                              isActive={value === TransferTemplateType.ACH}
                              onClick={() => !id && onChange(TransferTemplateType.ACH)}
                              isDisabled={!!id}
                            >
                              {id ? <OptionCheck /> : <OptionGroup.Option.Radio />}
                              <OptionGroup.Option.Label>ACH</OptionGroup.Option.Label>
                            </StyledOption>
                          )}

                          {((value === TransferTemplateType.Wire && id) || !id) && (
                            <StyledOption
                              isActive={value === TransferTemplateType.Wire}
                              onClick={() => !id && onChange(TransferTemplateType.Wire)}
                              isDisabled={!!id}
                            >
                              {id ? <OptionCheck /> : <OptionGroup.Option.Radio />}
                              <OptionGroup.Option.Label>Wire</OptionGroup.Option.Label>
                            </StyledOption>
                          )}
                        </OptionGroup>
                      ),
                    },
                  ],
                },
                {
                  headline: (
                    <>
                      Default Parameters <small>(Optional)</small>
                    </>
                  ),
                  description: 'These parameters apply to any new transfers added to the transfers list below',
                  fields: [
                    {
                      id:
                        watch('templateType') === TransferTemplateType.ACH
                          ? 'achDefaultParameters.bankAccountId'
                          : 'wireDefaultParameters.bankAccountId',
                      defaultValue: '',
                      label: 'Bank Account',
                      description:
                        watch('templateType') === TransferTemplateType.ACH
                          ? 'Select a bank account to originate the ACH transfer.'
                          : 'Select a bank account to originate the Wire transfer.',
                      children: ({ value, onChange, onBlur }, { error }) => (
                        <Dropdown
                          options={
                            bankAccounts?.bankAccounts.map((bankAccount) => ({
                              label: `${
                                bankAccount?.displayName && bankAccount?.description
                                  ? `${bankAccount.displayName} – ${bankAccount.description}`
                                  : bankAccount?.description || 'Unnamed'
                              } (${formatNumber(bankAccount?.balances?.availableAmount)})`,
                              small: bankAccount.id,
                              value: bankAccount.id,
                            })) ?? []
                          }
                          active={value}
                          fullWidth
                          maxWidth="640px"
                          search
                          searchLabel="Search for description"
                          onSearchChange={(description: string) => searchBankAccounts({ description })}
                          onChange={(baccId) => {
                            setValue('achDefaultParameters.bankAccountId', baccId);
                            setValue('wireDefaultParameters.bankAccountId', baccId);
                          }}
                          onOpenChange={(open) => !open && onBlur()}
                          hasError={!!error}
                        />
                      ),
                    },
                    {
                      id: 'achDefaultParameters.type',
                      hide: watch('templateType') !== TransferTemplateType.ACH,
                      label: 'Type',
                      description: 'Select between ACH Debit or ACH Credit.',
                      defaultValue: 'DEBIT',
                      children: ({ value, onChange }) => (
                        <OptionGroup orientation={OptionGroup.Orientation.Horizontal}>
                          <OptionGroup.Option isActive={value === 'DEBIT'} onClick={() => onChange('DEBIT')}>
                            <OptionGroup.Option.Radio />
                            <OptionGroup.Option.Label>Debit</OptionGroup.Option.Label>
                            <OptionGroup.Option.Description>
                              Pull money from a counterparty to your bank account.
                            </OptionGroup.Option.Description>
                          </OptionGroup.Option>

                          <OptionGroup.Option isActive={value === 'CREDIT'} onClick={() => onChange('CREDIT')}>
                            <OptionGroup.Option.Radio />
                            <OptionGroup.Option.Label>Credit</OptionGroup.Option.Label>
                            <OptionGroup.Option.Description>
                              Push money from your bank account to a counterparty.
                            </OptionGroup.Option.Description>
                          </OptionGroup.Option>
                        </OptionGroup>
                      ),
                    },
                    {
                      id: 'achDefaultParameters.entryClassCode',
                      hide: watch('templateType') !== TransferTemplateType.ACH,
                      label: 'Entry Class Code',
                      defaultValue: 'CCD',
                      tooltip: {
                        headline: 'Entry Class Code Definitions',
                        content: EntryClassCodeDefinitions,
                      },
                      children: ({ value, onChange, onBlur }, { error }) => (
                        <Dropdown
                          options={['CCD', 'CIE', 'CTX', 'PPD', 'TEL', 'WEB'].map((code: string) => ({
                            label: code,
                            value: code,
                          }))}
                          active={value}
                          fullWidth
                          onChange={onChange}
                          onOpenChange={(open) => !open && onBlur()}
                          hasError={!!error}
                        />
                      ),
                    },
                    {
                      id: 'wireDefaultParameters.description',
                      hide: watch('templateType') !== TransferTemplateType.Wire,
                      label: 'Description',
                      defaultValue: '',
                      children: ({ value, onChange, onBlur }) => (
                        <Input placeholder="Description" value={String(value)} onChange={onChange} onBlur={onBlur} />
                      ),
                    },
                  ],
                },
                {
                  headline: 'Transfers',
                  description:
                    'Create a list of default transfers. Parameters can be added to or edited when the template is in use.',
                  content: <TransferList type={watch('templateType')} />,
                },
              ],
            })}
            <ButtonWrapper>
              <Button type="button" size="small" variant="secondary" onClick={handleCancelClick}>
                Cancel
              </Button>
              <ButtonGroup>
                <Button type="submit" size="small">
                  {id ? 'Save Template' : 'Create Template'}
                </Button>
                {id && (
                  <Button type="button" size="small" onClick={handleReviewClick}>
                    Review
                  </Button>
                )}
              </ButtonGroup>
            </ButtonWrapper>
          </Wrapper>
        </Fade>
      </form>
    </FormProvider>
  );
};
