import React, { useState, ReactNode, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

import { Icon, InputContainer, InputElement, InputWrapper, Tooltip } from '@column/column-ui-kit';

import { copyToClipboard } from '~/util';

export interface CopyInputProps {
  className?: string;
  value?: string;
  placeholder?: string;
  icon?: ReactNode;
  disabled?: boolean;
  showValueHover?: boolean;
  size?: 'small' | 'default' | 'large';
}

const StyledInputElement = styled(InputElement)`
  mask-image: linear-gradient(90deg, rgb(0, 0, 0) 93%, transparent 100%);
`;

const CopyInputContainer = styled(InputContainer)`
  display: flex;
`;

export const Copy = styled.div<{ isEmpty: boolean }>`
  cursor: pointer;
  margin: -8px -6px -8px auto;
  padding-left: 6px;
  position: relative;
  z-index: 100;
  opacity: 0;
  transform: scale(0.9);
  transition:
    max-width 0.2s,
    opacity 0.2s,
    transform 0.2s;

  svg {
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(500)};
    padding: 4px;

    path {
      transition:
        fill 0.2s,
        stroke 0.2s;
    }
  }

  ${({ isEmpty }) =>
    isEmpty &&
    css`
      pointer-events: none;
      svg {
        --icon-color: ${({ theme }) => theme.secondary.blendToBackground(300)};
      }
    `}

  &:hover {
    svg {
      --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
    }
  }
`;

const StyledInputWrapper = styled(InputWrapper)`
  background-color: ${({ theme }) => theme.background};
  box-shadow:
    inset 0 0 0 var(--input-border, 1px)
      var(--input-border-color, ${({ theme }) => theme.secondary.blendToBackground(1100, 150)}),
    0 0 0 var(--input-outline, 0px) var(--input-outline-color, transparent);

  ${({ size }) =>
    size === 'small' &&
    css`
      padding: 8px 8px 8px 12px;
    `}

  &:hover {
    ${Copy} {
      opacity: 1;
      transform: scale(1);
    }
  }

  &:before {
    display: none;
  }
`;

export const CopyInput: React.FC<PropsWithChildren<CopyInputProps>> = (props) => {
  const [isTooltipShow, setIsTooltipShow] = useState<boolean>(false);
  const [showCopied, setShowCopied] = useState<boolean>(false);

  const handleCopyClick = () => {
    setIsTooltipShow(true);
    setShowCopied(true);
    copyToClipboard(props.value ?? '');
    setTimeout(() => {
      setIsTooltipShow(false);
      setTimeout(() => setShowCopied(false), 200);
    }, 550);
  };

  return (
    <StyledInputWrapper className={props.className} size={props.size}>
      <CopyInputContainer>
        <StyledInputElement
          value={props.value ?? ''}
          type="text"
          autoComplete="off"
          readOnly
          placeholder={props.placeholder}
          disabled={props.disabled}
        />
        <Copy onClick={handleCopyClick} isEmpty={!props.value}>
          <Tooltip
            content={showCopied ? 'Copied!' : (props.value ?? '')}
            maxWidth="280px"
            zIndex={100}
            isOpen={props.showValueHover ? undefined : isTooltipShow}
          >
            {props.icon ?? <Icon.Clipboard />}
          </Tooltip>
        </Copy>
      </CopyInputContainer>
      {props.children}
    </StyledInputWrapper>
  );
};
