import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  border-radius: 8px;
  box-shadow:
    ${({ theme }) => theme.style.buttonSecondaryBorderProperties}
      ${({ theme }) => theme.style.buttonSecondaryBorderWidth} ${({ theme }) => theme.style.buttonSecondaryBorderColor},
    0 0 0 var(--button-outline, 0px)
      var(--button-outline-color, ${({ theme }) => theme.style.buttonFocussedOutlineColor}),
    ${({ theme }) => theme.style.buttonSecondaryShadow};
`;

export const Card: React.FC<PropsWithChildren> = (props) => {
  return <Wrapper>{props.children}</Wrapper>;
};
