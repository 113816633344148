import React from 'react';
import styled, { css } from 'styled-components';

import { Icon } from '@column/column-ui-kit';

interface TimelineEventProps {
  event: TimelineEntryProps;
  date?: Date | string;
  transferParty?: React.ReactNode;
}

const Event = styled.div<{ $isLast?: boolean }>`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 25% auto 25%;
  padding-bottom: 24px;
`;

export const EventLabel = styled.div`
  line-height: 18px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.background};
  margin-bottom: 4px;
`;

export const EventText = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

const Status = styled.div<{ type?: 'success' | 'danger' | 'warning' | 'pending' }>`
  min-width: 248px;
  position: relative;
  padding-top: 16px;

  &:after {
    content: '';
    width: 2px;
    border-radius: 1px;
    background: ${({ theme }) => theme.secondary.blendToBackground(300)};
    position: absolute;
    top: 42px;
    bottom: -36px;
    left: 9px;
  }

  ${Event}:last-child &:after {
    display: none;
  }

  h4 {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    display: flex;
    gap: 8px;
    align-items: center;
    color: ${({ theme, type }) => theme.secondary.blendToBackground(type === 'pending' ? 600 : 1000)};
  }

  p {
    font-size: 12px;
    line-height: 18px;
    color: ${({ theme, type }) => theme.secondary.blendToBackground(type === 'pending' ? 400 : 800)};
    margin: 0 0 24px 28px;
  }

  svg {
    color: ${({ theme }) => theme.secondary.blendToBackground(600)};

    ${({ type }) =>
      type === 'success' &&
      css`
        color: ${({ theme }) => theme.success.background};
      `}

    ${({ type }) =>
      type === 'danger' &&
      css`
        color: ${({ theme }) => theme.danger.background};
      `}
  }
`;

interface TimelineEntryProps {
  field: string;
  label: string;
  type?: 'success' | 'danger' | 'warning' | 'pending';
}

export const RealtimeStatusTypes: TimelineEntryProps[] = [
  {
    label: 'Initiated',
    field: 'initiatedAt',
  },
  {
    label: 'Pending',
    field: 'pendingAt',
  },

  {
    label: 'Blocked',
    field: 'blockedAt',
    type: 'danger',
  },
  {
    label: 'Rejected',
    field: 'rejectedAt',
    type: 'danger',
  },
  {
    label: 'Manual Review',
    field: 'manualReviewAt',
  },
  {
    label: 'Manual Review Approved',
    field: 'manualReviewApprovedAt',
  },
  {
    label: 'Manual Review Rejected',
    field: 'manualReviewRejectedAt',
    type: 'danger',
  },
  {
    label: 'Accepted',
    field: 'acceptedAt',
  },
  {
    label: 'Completed',
    field: 'completedAt',
  },
];

export const TimelineEvent: React.FC<TimelineEventProps> = ({ event, date, transferParty }) => {
  const icon =
    event.type === 'pending' ? (
      <Icon.CircleBlank />
    ) : event.type === 'danger' ? (
      <Icon.CircleCross />
    ) : event.type === 'warning' ? (
      <Icon.CircleCheck />
    ) : (
      <Icon.CircleCheck />
    );

  return (
    <Event>
      <Status type={event.type || 'success'}>
        <h4>
          {icon}
          {event.label}
        </h4>
        {date && (
          <p>
            {typeof date === 'string' ? (
              date
            ) : (
              <>
                {date.toLocaleString('en-US', { weekday: 'long' })}, {date.toLocaleString('en-US', { month: 'long' })}{' '}
                {date.getDate()}, {date.getFullYear()} <br />
                {date.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}
              </>
            )}
          </p>
        )}
      </Status>
      {transferParty}
    </Event>
  );
};
