import React, { FC, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Chip, formatNumber, Icon, Toggle, Fade } from '@column/column-ui-kit';

import { Details } from '~/app/pages/Transfers/_components/Details';
import { ROUTE } from '~/app/routes';
import { CopyInput, PageHeader, RenderFields } from '~/components';
import { LogoLoading } from '~/elements';
import { useBankAccount, useCounterparty, useEntity } from '~/hooks';
import { useTransfer } from '~/hooks/useTransfers';
import { getProgressFromTransferStatus, getTransferProgressIcon } from '~/lib/transfers';
import { EntityWithDetails, RealtimeTransfer, transferStatusTooltips } from '~/repositories';
import { AccountNumber, BankAccount, BankAccountRepository } from '~/repositories/BankAccountRepository';
import { Counterparty } from '~/repositories/CounterpartyRepository';
import { useNotificationStore } from '~/stores/Notification';
import { FormElement, FormLabel, Inner } from '~/styles';
import { RealtimeTransferStatus } from '~/typings/enum';
import { formatString, getDateLongUTC } from '~/util';

import { TimelineEvent, RealtimeStatusTypes } from './_components/TimelineEvent';
import { TransferCard } from './_components/TransferCard';

interface Params {
  id: string;
}

enum EntityTypes {
  PERSON = 'PERSON',
  BUSINESS = 'BUSINESS',
}

const StyledFade = styled(Fade)`
  margin-top: 10%;
`;

const TransferSummary = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
`;

const TransferAmount = styled.div`
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.02em;
  font-weight: 600;
  display: flex;
  gap: 4px;
  align-items: baseline;
`;

const TransferCurrency = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.blendToBackground(500)};
`;

const TransferMeta = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

const TransferDate = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

const TransferID = styled.div`
  min-width: 310px;
  max-width: 25%;
`;

const Timeline = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.secondary.blendToBackground(200)};
`;

const Heading = styled.h3`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin: 0 0 24px;
`;

const InfoForm = styled.form`
  border-top: 1px solid ${({ theme }) => theme.secondary.blendToBackground(200)};
`;

const statusEvents = RealtimeStatusTypes;

interface TimelineEventsProps {
  transfer: RealtimeTransfer;
  bankAccount: BankAccount;
  accountNumber: AccountNumber;
  counterparty: Counterparty;
  entity: EntityWithDetails;
  navigate: (path: string) => void;
}

const TimelineEvents: FC<TimelineEventsProps> = ({
  transfer,
  bankAccount,
  accountNumber,
  counterparty,
  entity,
  navigate,
}) => {
  const completedEvents = statusEvents
    .map((event) => {
      const rawDate =
        event.field === 'initiatedAt' && transfer.completedAt
          ? transfer.completedAt
          : (transfer[event.field as keyof typeof transfer] as string | Date | undefined);

      const date = rawDate ? new Date(rawDate) : undefined;

      if (!date) return null;

      const isSender = event.field === 'initiatedAt';
      const isReceiver = !isSender;

      return (
        <TimelineEvent
          key={event.field}
          event={event}
          date={date}
          transferParty={
            isSender || transfer.completedAt ? (
              <TransferCard
                title={
                  transfer.isIncoming
                    ? isReceiver
                      ? (bankAccount?.description ?? 'Unnamed Bank Account')
                      : (counterparty?.name ?? 'Unnamed Counterparty')
                    : isReceiver
                      ? (counterparty?.name ?? 'Unnamed Counterparty')
                      : (bankAccount?.description ?? 'Unnamed Bank Account')
                }
                onClick={
                  transfer.isIncoming
                    ? isReceiver
                      ? () => navigate(`${ROUTE.BANK_ACCOUNTS}/edit/${bankAccount?.id}`)
                      : () => navigate(`${ROUTE.COUNTERPARTIES}/edit/${counterparty?.id}`)
                    : isReceiver
                      ? () => navigate(`${ROUTE.COUNTERPARTIES}/edit/${counterparty?.id}`)
                      : () => navigate(`${ROUTE.BANK_ACCOUNTS}/edit/${bankAccount?.id}`)
                }
                amount={transfer?.amount ?? 0}
                isIncoming={isReceiver}
                icon={
                  transfer.isIncoming
                    ? isReceiver
                      ? (entity?.type.toLowerCase() as 'person' | 'business')
                      : counterparty?.legalType === 'individual'
                        ? 'person'
                        : 'business'
                    : isReceiver
                      ? counterparty?.legalType === 'individual'
                        ? 'person'
                        : 'business'
                      : (entity?.type.toLowerCase() as 'person' | 'business')
                }
                status={
                  isReceiver
                    ? transfer.status === RealtimeTransferStatus.Completed
                      ? 'success'
                      : 'pending'
                    : undefined
                }
                details={
                  transfer.isIncoming
                    ? [
                        {
                          label: isReceiver ? 'Bank Account ID' : 'Counterparty ID',
                          value: String(isReceiver ? transfer?.bankAccountId : transfer?.counterpartyId),
                        },
                        {
                          label: 'Routing Number',
                          value: isReceiver ? accountNumber?.routingNumber : String(counterparty?.routingNumber),
                        },
                        {
                          label: 'Account Number',
                          value: isReceiver ? accountNumber?.accountNumber : String(counterparty?.accountNumber),
                        },
                      ]
                    : [
                        {
                          label: isReceiver ? 'Counterparty ID' : 'Bank Account ID',
                          value: String(isReceiver ? transfer?.counterpartyId : transfer?.bankAccountId),
                        },
                        {
                          label: 'Routing Number',
                          value: isReceiver ? String(counterparty?.routingNumber) : accountNumber?.routingNumber,
                        },
                        {
                          label: 'Account Number',
                          value: isReceiver ? String(counterparty?.accountNumber) : accountNumber?.accountNumber,
                        },
                      ]
                }
              />
            ) : undefined
          }
        />
      );
    })
    .filter(Boolean);

  if (!transfer.completedAt) {
    completedEvents.push(
      <TimelineEvent
        key="pending-completion"
        event={{
          field: 'pendingCompletion',
          label:
            transfer.status === RealtimeTransferStatus.Rejected || transfer.status === RealtimeTransferStatus.Blocked
              ? 'Incomplete'
              : 'Completed',
          type: 'pending',
        }}
        date={
          transfer.status !== RealtimeTransferStatus.Rejected && transfer.status !== RealtimeTransferStatus.Blocked
            ? 'Pending'
            : undefined
        }
        transferParty={
          <TransferCard
            title={
              transfer.isIncoming
                ? (bankAccount?.description ?? 'Unnamed Bank Account')
                : (counterparty?.name ?? 'Unnamed Counterparty')
            }
            onClick={
              transfer.isIncoming
                ? () => navigate(`${ROUTE.BANK_ACCOUNTS}/${bankAccount?.id}`)
                : () => navigate(`${ROUTE.COUNTERPARTIES}/edit/${counterparty?.id}`)
            }
            amount={transfer?.amount ?? 0}
            isIncoming
            icon={entity?.type.toLowerCase() as 'person' | 'business'}
            status={
              transfer.status === RealtimeTransferStatus.Rejected || transfer.status === RealtimeTransferStatus.Blocked
                ? 'danger'
                : 'pending'
            }
            details={
              transfer.isIncoming
                ? [
                    { label: 'Bank Account ID', value: String(transfer?.bankAccountId) },
                    { label: 'Routing Number', value: accountNumber?.routingNumber },
                    { label: 'Account Number', value: accountNumber?.accountNumber },
                  ]
                : [
                    { label: 'Counterparty ID', value: String(transfer?.counterpartyId) },
                    { label: 'Routing Number', value: String(counterparty?.routingNumber) },
                    { label: 'Account Number', value: String(counterparty?.accountNumber) },
                  ]
            }
          />
        }
      />
    );
  }

  return <>{completedEvents}</>;
};

export const PageTransfersRealtime: FC = () => {
  const { id } = useParams<keyof Params>() as Params;
  const navigate = useNavigate();
  const { addDangerNotification } = useNotificationStore();
  const [accountNumber, setAccountNumber] = useState<any>({});
  const [isAccountNumberLoading, setIsAccountNumberLoading] = useState<boolean>(true);

  const { response: bankAccount, createRequest: fetchBankAccount } = useBankAccount({
    onError: (error) => {
      addDangerNotification({ content: error.message });
      navigate(ROUTE.TRANSFERS);
    },
  });

  const { response: entity, createRequest: fetchEntity } = useEntity({
    onError: (error) => {
      addDangerNotification({ content: error.message });
      navigate(ROUTE.TRANSFERS);
    },
  });

  const { response: transfer, isLoading } = useTransfer<RealtimeTransfer>('realtime')({
    initialParams: { id },
    onSuccess: async (response) => {
      fetchAccountNumber(response.accountNumberId);

      try {
        if (response.bankAccountId) {
          const currentBankAccount = await fetchBankAccount({ id: response.bankAccountId });

          if (!currentBankAccount) {
            return;
          }

          await fetchEntityType(currentBankAccount);
        }
      } catch (error) {
        addDangerNotification({ content: (error as Error).message });
      }

      if (response?.counterpartyId) {
        fetchCounterparty({ id: response.counterpartyId });
      }
    },

    onError: (error) => {
      addDangerNotification({ content: error.message });
      navigate(ROUTE.TRANSFERS);
    },
  });

  const { response: counterparty, createRequest: fetchCounterparty } = useCounterparty({
    onError: (error) => addDangerNotification({ content: error.message }),
  });

  const fetchAccountNumber = (accountNumberId?: string) => {
    if (!accountNumberId) {
      setIsAccountNumberLoading(false);
      return;
    }

    setIsAccountNumberLoading(true);
    BankAccountRepository.getAccountNumber({ id: accountNumberId })
      .then(setAccountNumber)
      .catch((error) => {
        addDangerNotification({ content: error.message });
      })
      .finally(() => setIsAccountNumberLoading(false));
  };

  const fetchEntityType = async (currentBankAccount: BankAccount): Promise<'PERSON' | 'BUSINESS'> => {
    if (!currentBankAccount) {
      setIsAccountNumberLoading(false);
      addDangerNotification({ content: 'Bank Account not found' });

      return 'BUSINESS';
    }

    setIsAccountNumberLoading(true);

    try {
      const currentEntity = await fetchEntity({ id: currentBankAccount.owners?.[0] });

      if (!currentEntity) {
        addDangerNotification({ content: 'Entity not found' });

        return 'BUSINESS';
      }

      return currentEntity.type === EntityTypes.PERSON ? 'PERSON' : 'BUSINESS';
    } catch (error) {
      addDangerNotification({ content: (error as Error).message });

      return 'BUSINESS';
    } finally {
      setIsAccountNumberLoading(false);
    }
  };

  const transferProgress = useMemo(
    () => (transfer?.status ? getProgressFromTransferStatus(transfer.status) : undefined),
    [transfer?.status]
  );

  const formMethods = useForm<RealtimeTransfer>();

  useEffect(() => {
    if (transfer) {
      formMethods.setValue('description', transfer.description || '-');
      formMethods.setValue('idempotencyKey', transfer.idempotencyKey || '-');
      formMethods.setValue('allowOverdraft', transfer.allowOverdraft || false);
    }
  }, [transfer]);

  const isDataLoading = useMemo(() => {
    return isLoading || isAccountNumberLoading;
  }, [isLoading, isAccountNumberLoading]);

  return (
    <>
      <StyledFade show={isDataLoading}>
        <LogoLoading />
      </StyledFade>
      <Fade show={!isDataLoading}>
        <PageHeader text="Transfer" backButton />
        <Inner>
          <TransferSummary>
            <div>
              <TransferAmount>
                {formatNumber(transfer?.amount)}
                <TransferCurrency>USD</TransferCurrency>
              </TransferAmount>
              <TransferMeta>
                <Chip>Realtime</Chip>
                {transfer?.isIncoming ? (
                  <Chip icon={<Icon.CircleArrowDown />}>Incoming</Chip>
                ) : (
                  <Chip icon={<Icon.CircleArrowUp />}>Outgoing</Chip>
                )}
                {transfer?.status && (
                  <Chip
                    icon={transferProgress && getTransferProgressIcon(transferProgress)}
                    tooltip={
                      transferStatusTooltips.has(String(transfer.status).toLowerCase())
                        ? {
                            content: transferStatusTooltips.get(String(transfer.status).toLowerCase()),
                            delay: 200,
                            triggerClick: false,
                          }
                        : undefined
                    }
                    type={
                      transfer.status === RealtimeTransferStatus.Rejected ||
                      transfer.status === RealtimeTransferStatus.Blocked
                        ? 'danger'
                        : transfer.status === RealtimeTransferStatus.Completed
                          ? 'success'
                          : 'default'
                    }
                  >
                    {formatString(transfer.status)}
                  </Chip>
                )}
                <TransferDate>{transfer?.initiatedAt ? getDateLongUTC(transfer.initiatedAt) : ''}</TransferDate>
              </TransferMeta>
            </div>

            <TransferID>
              <FormElement>
                <FormLabel>Transfer ID</FormLabel>
                <CopyInput value={transfer?.id} size="small" />
              </FormElement>
            </TransferID>
          </TransferSummary>
        </Inner>

        <Timeline>
          <Inner>
            <Heading>Timeline</Heading>
            {bankAccount && accountNumber && counterparty && entity && transfer && (
              <TimelineEvents
                transfer={transfer}
                bankAccount={bankAccount}
                accountNumber={accountNumber}
                counterparty={counterparty}
                entity={entity}
                navigate={navigate}
              />
            )}
          </Inner>
        </Timeline>

        <FormProvider {...formMethods}>
          <InfoForm autoComplete="off">
            {RenderFields<RealtimeTransfer>({
              sections: [
                {
                  headline: 'Information',
                  fields: [
                    {
                      id: 'description',
                      label: 'Description',
                      children: ({ value }) => {
                        return <CopyInput value={String(value)} />;
                      },
                    },
                    {
                      id: 'idempotencyKey',
                      label: 'Indempotency Key',
                      children: ({ value }) => {
                        return <CopyInput value={String(value) || ''} />;
                      },
                    },
                    {
                      id: 'allowOverdraft',
                      label: 'Allow Overdraft',
                      children: ({ value, onChange }) => {
                        const isChecked = typeof value === 'boolean' ? value : false;
                        return <Toggle isChecked={isChecked} onCheckedChange={onChange} isDisabled />;
                      },
                    },
                  ],
                },
              ],
            })}
            <Details data={transfer as Record<string, unknown>} />
          </InfoForm>
        </FormProvider>
      </Fade>
    </>
  );
};
