import React, { FC, Fragment } from 'react';
import styled, { css } from 'styled-components';

import { formatNumber } from '@column/column-ui-kit';

import { Card, CopyInput } from '~/components';
import { isValidDate, getDateLongUTC } from '~/util';

interface TransferDetailsProps {
  data?: Record<string, unknown>;
  children?: React.ReactNode;
}

const Wrapper = styled.div`
  display: grid;
  grid-gap: 24px;
  align-items: start;
  grid-template-columns: 25% auto 25%;
  padding: 0 24px 24px;
`;

const Title = styled.div`
  line-height: 1;
  display: grid;
  grid-gap: 4px;
`;

const Label = styled.div`
  margin-top: 12px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.foreground};
`;

const StyledCard = styled(Card)`
  min-width: 248px;
`;

const DetailsList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  gap: 0;

  ul {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(150)};
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 16px;

    li {
      margin: 0;
      padding-left: 16px;
      background: ${({ theme }) =>
        theme.id !== 'dark' ? theme.secondary.blendToBackground(25) : theme.secondary.blendToBackground(5)};
      border-top: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};

      &:first-child {
        border-top: 0;
        border-radius: 0 0 8px 8px;
      }
    }
  }
`;

const DetailRow = styled.li<{ parent?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  border-top: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};
  padding: 0 0 0 16px;
  position: relative;
  z-index: 1;

  ${({ parent }) =>
    parent === true &&
    css`
      flex-direction: column;
      align-items: flex-start;
      padding: 4px 0;
      margin: 4px 0 0;

      ${DetailsList} {
        width: 100%;
        background: ${({ theme }) => theme.secondary.blendToBackground(25)};
        border: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};
        border-radius: 8px;

        > div {
          padding-left: 24px;
        }
      }
    `}

  &:first-child {
    border-top: 0;
  }
`;

const Param = styled.label`
  flex: 1;
  white-space: nowrap;
  font-weight: 500;
  font-size: 14px;
  min-height: 40px;
  display: flex;
  align-items: center;
`;

const ValueInput = styled(CopyInput)`
  background: transparent;
  padding: 8px;
  margin: 8px;
  height: 32px;
  font-size: 14px;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  box-shadow: none;

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.secondary.blendToBackground(50)};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(300)};
  }
`;

const formatLabel = (content: string): string => {
  const exceptions: { [key: string]: string } = {
    id: 'ID',
    micr: 'MICR',
    fi: 'FI',
    di: 'DI',
    imad: 'IMAD',
    uetr: 'UETR',
    fx: 'FX',
    fis: 'FIS',
    omad: 'OMAD',
    line1: 'Line 1',
    line2: 'Line 2',
    line3: 'Line 3',
    line4: 'Line 4',
    line5: 'Line 5',
    line6: 'Line 6',
  };

  const words = content
    .replace(/([A-Z])/g, ' $1')
    .toLowerCase()
    .split(' ')
    .map((word) => {
      return exceptions[word] || word;
    });

  return words
    .join(' ')
    .replace(/\b\w/g, (s) => s.toUpperCase())
    .trim();
};

export const Details: FC<TransferDetailsProps> = ({ data, children }) => (
  <Wrapper>
    <Title>
      <Label>Transfer Details</Label>
    </Title>
    <StyledCard>
      <DetailsList> {data ? TransferObject(data) : children}</DetailsList>
    </StyledCard>
  </Wrapper>
);

const ElementRow: FC<{ label: string; content?: string; parent?: boolean }> = ({ label, content, parent }) => (
  <DetailRow parent={parent}>
    <Param>{formatLabel(label)}</Param>
    {typeof content !== 'undefined' && (
      <ValueInput value={label.toLowerCase() === 'amount' ? formatNumber(Number(content)) : content} />
    )}
  </DetailRow>
);

export const TransferObject = (obj: Record<string, any>, parent?: string) => {
  const elements = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (Array.isArray(obj[key]) && obj[key].length === 0) {
        elements.push(<ElementRow key={parent ? `${key}-${parent}` : key} label={key} content="[]" />);
      } else if (isValidDate(obj[key])) {
        elements.push(
          <ElementRow key={parent ? `${key}-${parent}` : key} label={key} content={getDateLongUTC(obj[key])} />
        );
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        elements.push(
          <Fragment key={parent ? `${key}-${parent}` : key}>
            <ElementRow label={key} parent />
            <DetailsList>{TransferObject(obj[key], key)}</DetailsList>
          </Fragment>
        );
      } else if (typeof obj[key] === 'boolean') {
        elements.push(<ElementRow key={parent ? `${key}-${parent}` : key} label={key} content={obj[key].toString()} />);
      } else {
        elements.push(<ElementRow key={parent ? `${key}-${parent}` : key} label={key} content={obj[key] ?? '-'} />);
      }
    }
  }
  return elements;
};
