import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  Icon,
  Button,
  Input,
  Dropdown,
  AmountInput,
  Toggle,
  Tooltip,
  formatNumber,
  CurrenciesUSD,
} from '@column/column-ui-kit';

import { Balance, FormTextAmount, FormLabelAmount } from '../BankAccounts/Edit';
import { ROUTE } from '~/app/routes';
import { PageHeader, SectionHeader, DateInput, CopyInput, PaginationWrapper, RestrictedBanner } from '~/components';
import { EntityRepository, Loan, LoanRepository } from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { EditToolbar, FormElement, FormLabel, FormText, Inner, Grid } from '~/styles';
import { UpdateLoanRequest } from '~/typings/API';
import { getDateLongUTC } from '~/util';

import { handleFetchDisbursements, disbursementColumns, disbursementFilter } from './Disbursement/Overview';
import { handleFetchPayments, paymentColumns, paymentFilter } from './Payment/Overview';

interface Params {
  id: string;
}

const ToggleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  min-height: 40px;

  svg {
    --icon-size: 18px;
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  }
`;

export const PageLoansEdit: React.FC = () => {
  const { currentUser, currentPermission } = useSessionStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const navigate = useNavigate();
  const { id } = useParams<keyof Params>() as Params;
  const [loan, setLoan] = useState<Partial<Loan>>({});
  const [entityName, setEntityName] = useState<string>('');
  const [entityId, setEntityId] = useState<string>('');

  const handleUpdate = () => {
    loan.maxPrincipalBalance = loan.maxPrincipalBalance?.toString();

    const updateLoan = loan;

    for (const property in updateLoan) {
      if (updateLoan.hasOwnProperty(property)) {
        if (!['description', 'isRevolving', 'maturityDate', 'maxPrincipalBalance', 'status'].includes(property)) {
          delete updateLoan[property as keyof Loan];
        }
      }
    }

    LoanRepository.update(id, updateLoan as UpdateLoanRequest)
      .then((response) => {
        addSuccessNotification({
          content: `${response.description} updated`,
        });
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
        });
      });
  };

  const fetchData = () => {
    LoanRepository.get(id)
      .then((response) => {
        setLoan(response);

        if (!response?.primarySignerEntityId) {
          return;
        }

        EntityRepository.get({ id: response.primarySignerEntityId })
          .then((entityResponse) => {
            if (!entityResponse.id) {
              return;
            }

            setEntityId(entityResponse.id);
            if (entityResponse?.personDetails) {
              setEntityName(`${entityResponse.personDetails.firstName} ${entityResponse.personDetails.lastName}`);
            }
            if (entityResponse?.businessDetails) {
              if (!entityResponse.businessDetails.businessName) {
                return;
              }

              setEntityName(entityResponse.businessDetails.businessName);
            }
          })
          .catch((error) => {
            addDangerNotification({
              content: error.message,
            });
          });
      })
      .catch((error) => {
        navigate(ROUTE.LOANS);
        addDangerNotification({
          content: error.message,
        });
      });
  };

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    if (id) {
      fetchData();
    }
  }, [id]);

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => ({
          isSandbox: state.isSandbox,
          isLoading: state.isLoading,
          defaultPlatformId: state.currentUser?.defaultPlatformId,
        }),
        () => {
          if (!currentUser) {
            return;
          }
          if (id) {
            fetchData();
          }
        }
      ),
    []
  );

  return (
    <>
      <PageHeader text={loan.description || 'Loan'} />

      {currentPermission?.loans !== 'write' && (
        <Inner pb={0}>
          <RestrictedBanner />
        </Inner>
      )}

      <Inner data-disabled={currentPermission?.loans !== 'write'}>
        <Balance>
          <FormElement>
            <FormTextAmount>{formatNumber(loan.balances?.principalOutstanding)}</FormTextAmount>
            <FormLabelAmount>Outstanding</FormLabelAmount>
          </FormElement>
          <FormElement>
            <FormTextAmount>{formatNumber(loan.balances?.principalPaid)}</FormTextAmount>
            <FormLabelAmount>Paid</FormLabelAmount>
          </FormElement>
          <FormElement>
            <FormTextAmount>{formatNumber(loan.balances?.principalChargedOff)}</FormTextAmount>
            <FormLabelAmount>Charged off</FormLabelAmount>
          </FormElement>
          <FormElement>
            <FormTextAmount>{formatNumber(loan.balances?.principalHolding)}</FormTextAmount>
            <FormLabelAmount>Holding</FormLabelAmount>
          </FormElement>
        </Balance>
      </Inner>

      <SectionHeader text="Information" border />
      <Inner pt={16}>
        <Grid>
          <FormElement newRow>
            <FormLabel>Description</FormLabel>
            <Input
              onChange={(value: string) => setLoan({ ...loan, description: value })}
              value={loan?.description ?? ''}
              placeholder="Description"
            />
          </FormElement>
          <FormElement>
            <FormLabel>Is Revolving?</FormLabel>
            <ToggleWrapper>
              <Toggle
                isChecked={loan.isRevolving}
                onCheckedChange={(value: boolean) => setLoan({ ...loan, isRevolving: value })}
              />
              <Tooltip content="Indicates whether or not the loan is revolving. If checked the loan can have multiple disbursements.">
                <Icon.CircleQuestionmark />
              </Tooltip>
            </ToggleWrapper>
          </FormElement>

          <FormElement>
            <FormLabel>Entity</FormLabel>
            <FormText>{entityName}</FormText>
          </FormElement>
          <FormElement>
            <FormLabel>Entity ID</FormLabel>
            <CopyInput value={entityId ?? ''} placeholder="" />
          </FormElement>

          <FormElement>
            <FormLabel>Maturity Date</FormLabel>
            <DateInput
              onChange={(value?: string) => setLoan({ ...loan, maturityDate: value })}
              value={loan?.maturityDate}
            />
          </FormElement>
          <FormElement>
            <FormLabel>Max Principal Balance</FormLabel>
            <AmountInput
              onChange={(value?: number) => setLoan({ ...loan, maxPrincipalBalance: value?.toString() })}
              onCurrencyCodeChange={(value: string) => setLoan({ ...loan, currency: value })}
              currencyCode={loan.currency}
              currencyList={CurrenciesUSD}
              value={loan.maxPrincipalBalance ? parseInt(loan.maxPrincipalBalance, 10) : undefined}
            />
          </FormElement>
          <FormElement>
            <FormLabel>Status</FormLabel>
            <Dropdown
              options={[
                {
                  label: 'Current',
                  value: 'current',
                },
                {
                  label: 'Delinquent',
                  value: 'delinquent',
                },
                {
                  label: 'Charged off',
                  value: 'charged_off',
                },
                {
                  label: 'In Dispute',
                  value: 'in_dispute',
                },
                {
                  label: 'Canceled',
                  value: 'canceled',
                },
                {
                  label: 'Paid off',
                  value: 'paid_off',
                },
              ]}
              fullWidth
              active={loan.status}
              onChange={(value: string) => setLoan({ ...loan, status: value })}
            />
          </FormElement>
          {[
            { label: 'Created at', key: 'createdAt' },
            { label: 'Charged off at', key: 'charged_off_at' },
            { label: 'Paid off at', key: 'paidOffAt' },
            { label: 'Delinquent at', key: 'delinquentAt' },
            { label: 'Disputed at', key: 'disputedAt' },
          ].map((o) => {
            const value = loan[o.key as keyof Loan];

            if (!value) {
              return;
            }

            return (
              <FormElement key={o.key}>
                <FormLabel>{o.label}</FormLabel>
                <FormText>{value && getDateLongUTC(value as any)}</FormText>
              </FormElement>
            );
          })}
        </Grid>
      </Inner>

      <Inner pt={0}>
        <EditToolbar>
          <Button onClick={() => navigate(-1)} variant="secondary" size="small">
            Back
          </Button>
          <Button onClick={handleUpdate} isDisabled={currentPermission?.loans !== 'write'} size="small">
            Update
          </Button>
        </EditToolbar>
      </Inner>

      <SectionHeader text="Payments" border={false}>
        <Button onClick={() => navigate(`${ROUTE.LOANS}/edit/${id}/payment`)} icon={<Icon.Plus />} size="small">
          Create Payment
        </Button>
      </SectionHeader>

      <PaginationWrapper
        tableId="loanPayments"
        fetch={(o) => handleFetchPayments(o, navigate)}
        columns={paymentColumns}
        filter={paymentFilter}
        rowClick={(row) => {
          navigate(`${ROUTE.LOANS}/payments/edit/${row.original.id}`);
        }}
      />

      <SectionHeader text="Disbursements" border={false}>
        <Button onClick={() => navigate(`${ROUTE.LOANS}/edit/${id}/disbursement`)} icon={<Icon.Plus />} size="small">
          Create Disbursement
        </Button>
      </SectionHeader>
      <PaginationWrapper
        tableId="loanDisbursements"
        fetch={(o) => handleFetchDisbursements(o, navigate)}
        columns={disbursementColumns}
        filter={disbursementFilter}
        rowClick={(row) => {
          navigate(`${ROUTE.LOANS}/disbursements/edit/${row.original.id}`);
        }}
      />
    </>
  );
};
