import React, { type FC } from 'react';
import styled, { css } from 'styled-components';

import { Icon, formatNumber } from '@column/column-ui-kit';

import { CopyInput } from '~/components';
import { Inner } from '~/styles';

interface TransferCardProps {
  title: string;
  onClick: () => void;
  amount: number;
  isIncoming?: boolean;
  icon: 'person' | 'business';
  status?: 'success' | 'danger' | 'pending';
  details: { label: string; value: string }[];
}

const Meta = styled.div`
  flex: auto;
  display: flex;
  gap: 12px;
  align-items: center;
`;

const Title = styled.h4`
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  font-weight: 500;
  display: flex;
  align-items: center;

  svg {
    opacity: 0;
    transform: translateX(-4px);
    transition:
      opacity 0.2s,
      transform 0.2s;
  }
`;

const Amount = styled.strong<{ status?: 'success' | 'danger' | 'pending' }>`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};

  ${({ status }) =>
    status === 'pending' &&
    css`
      background: linear-gradient(
        to right,
        ${({ theme }) => theme.secondary.blendToBackground(100)} 20%,
        ${({ theme }) => theme.secondary.blendToBackground(400)} 40%,
        ${({ theme }) => theme.secondary.blendToBackground(400)} 60%,
        ${({ theme }) => theme.secondary.blendToBackground(100)} 80%
      );
      background-size: 200% auto;

      color: ${({ theme }) => theme.secondary.blendToBackground(800)};
      background-clip: text;
      text-fill-color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      animation: pending 2s linear infinite;
      @keyframes pending {
        to {
          background-position: -200% center;
        }
      }
    `}

  ${({ status }) =>
    status === 'success' &&
    css`
      color: ${({ theme }) => theme.success.background};
    `}
  ${({ status }) =>
    status === 'danger' &&
    css`
      color: ${({ theme }) => theme.danger.background};
      text-decoration: line-through;
    `}
`;

const Account = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 5px;
  transition:
    background 0.2s,
    box-shadow 0.2s;

  &:hover {
    background: ${({ theme }) => theme.secondary.blendToBackground(25)};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(150)};

    svg {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &:active {
    ${Title} svg {
      transform: translateX(2px);
      transition: transform 0.1s;
    }
  }
`;

const Card = styled.div`
  border-radius: 8px;

  box-shadow:
    ${({ theme }) => theme.style.buttonSecondaryBorderProperties}
      ${({ theme }) => theme.style.buttonSecondaryBorderWidth} ${({ theme }) => theme.style.buttonSecondaryBorderColor},
    0 0 0 var(--button-outline, 0px)
      var(--button-outline-color, ${({ theme }) => theme.style.buttonFocussedOutlineColor}),
    ${({ theme }) => theme.style.buttonSecondaryShadow};
  min-width: 248px;
`;

const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.secondary.blendToBackground(600)};
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: ${({ theme }) => theme.secondary.blendToBackground(50)};
  box-shadow: 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(150)};
`;

const CopyRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  line-height: 16px;
  overflow: hidden;
  border-top: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};
`;

const Label = styled.div`
  padding: 16px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  color: ${({ theme }) => theme.secondary.background};
`;

const StyledCopyInput = styled(CopyInput)`
  background: transparent;
  padding: 8px;
  margin: 8px;
  height: 32px;
  font-size: 14px;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  box-shadow: none;

  &:hover {
    background: ${({ theme }) => theme.secondary.blendToBackground(50)};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(300)};
  }
`;

const Direction = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

interface CopyRowProps {
  label: string;
  value: string;
}

const CopyRow: FC<CopyRowProps> = (props) => {
  return (
    <CopyRowWrapper>
      <Label>{props.label}</Label>
      <StyledCopyInput value={props.value} />
    </CopyRowWrapper>
  );
};

export const TransferCard: React.FC<TransferCardProps> = ({
  title,
  amount,
  isIncoming,
  icon,
  status,
  details,
  onClick,
}) => (
  <Card>
    <Inner p={8}>
      <Account onClick={onClick}>
        <Meta>
          <Avatar>{icon === 'person' ? <Icon.User /> : <Icon.Business />}</Avatar>
          <div>
            <Title>
              {title}
              <Icon.ChevronRight />
            </Title>
            <Amount status={status}>
              {!isIncoming && '-'}
              {formatNumber(amount)}
            </Amount>
          </div>
        </Meta>
        {isIncoming ? (
          <Direction>
            Incoming <Icon.CircleIncoming />
          </Direction>
        ) : (
          <Direction>
            Outgoing <Icon.CircleOutgoing />
          </Direction>
        )}
      </Account>
    </Inner>
    {details.map(({ label, value }) => (
      <CopyRow key={label} label={label} value={value} />
    ))}
  </Card>
);
