import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button, Dropdown, DropdownOption, Icon, Input } from '@column/column-ui-kit';

import { Modal, ModalSize, NotificationList } from '~/components';
import { CreatePlatformInviteRequest, Invite, InviteRepository } from '~/repositories';
import { precannedRolesByID, precannedRolesByName } from '~/repositories/RoleRepository';
import { useHelpSidebarStore } from '~/stores/HelpSidebar';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { FormElement, FormLabel, Line, Paragraph } from '~/styles';
import { validateEmail } from '~/util';

interface Props {
  open: boolean;
  onClose: () => void;
  onSave: (invites: Invite[]) => void;
  roles: DropdownOption[];
}

interface InviteEntry {
  email: string;
  roleId: string;
}

const Form = styled.form`
  display: grid;
  grid-gap: 24px;
  padding-top: 8px;
`;

const Fields = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: auto-fill;
  text-align: left;
`;

const Inner = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const Columns = styled.div`
  margin-top: -2px;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 40% auto;
`;

const AddAnother = styled(Button)`
  margin-top: 4px;
  padding: 0;
  justify-self: start;
`;

const Mailbox = styled(Icon.AnimationMailbox)`
  --icon-background: ${({ theme }) => theme.primary.blendToBackground(100)};
`;

const Email = styled(Input)`
  display: flex;
  align-items: center;

  & > div {
    flex-grow: 1;
  }
`;

const Remove = styled(Icon.Cross)`
  --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  cursor: pointer;
  margin-top: -2px;
  margin-bottom: -2px;

  path {
    transition: stroke 0.2s;
  }

  &:hover {
    --icon-color: ${({ theme }) => theme.secondary.background};
  }
`;

const RoleHelpButton = styled(Button)`
  padding: 0;
  font-weight: 500;
  --button-color: ${({ theme }) => theme.foreground};

  svg {
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  }

  &:hover {
    --button-color: ${({ theme }) => theme.primary.background};

    svg {
      --icon-color: ${({ theme }) => theme.primary.background};
    }
  }
`;

const AdminRoleId = precannedRolesByName.admin.id;

export const InviteUsersModal: React.FC<Props> = ({ onClose, onSave, open, roles }) => {
  const { currentPlatform } = useSessionStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const openHelpSidebar = useHelpSidebarStore((state) => state.openHelpSidebar);

  const [inviteEntry, setInviteEntry] = useState<InviteEntry[]>([{ roleId: AdminRoleId, email: '' }]);
  useEffect(() => {
    if (open) {
      setInviteEntry([{ roleId: AdminRoleId, email: '' }]);
    }
  }, [open]);

  const handleSubmit = () => {
    const platformId = currentPlatform?.id;

    if (!platformId) {
      addDangerNotification({
        content: 'No Platform ID provided, please reload and try again',
        display: 'popup',
      });
      return;
    }

    const validEmails = inviteEntry.filter(
      (entry: InviteEntry) => entry.email.length > 0 && validateEmail(entry.email)
    );

    if (validEmails.length < 1) {
      addDangerNotification({
        content: 'Enter a valid email address',
        display: 'popup',
      });
      return;
    }

    Promise.all(
      validEmails.map((entry: InviteEntry) => {
        const platformRole =
          precannedRolesByID[entry.roleId] !== undefined ? precannedRolesByID[entry.roleId].name : undefined;
        const roleId = platformRole === undefined ? entry.roleId : undefined;

        const request: CreatePlatformInviteRequest = {
          platformId,
          email: entry.email,
          platformRole,
          roleId,
        };

        return InviteRepository.create(request);
      })
    )
      .then((invites) => {
        onSave(invites);
        addSuccessNotification({
          content:
            validEmails.length === 1
              ? `Invite sent to "${validEmails.at(0)?.email}".`
              : `Invites sent to "${validEmails.map((emailEntry) => emailEntry.email).join(', ')}".`,
        });
      })
      .catch((error: any) => {
        addDangerNotification({
          content: error.message,
          display: 'popup',
        });
      });
  };

  const handleAddEmail = () => {
    setInviteEntry((s) => [...s, { roleId: AdminRoleId, email: '' }]);
  };

  const removeEmail = (e: React.MouseEvent, index: number) => {
    e.stopPropagation();
    setInviteEntry((s) => s.filter((_, i: number) => i !== index));
  };

  return (
    <Modal
      headline={'Invite users'}
      icon={
        <Mailbox
          counter={
            inviteEntry.filter((entry: InviteEntry) => entry.email.length > 0 && validateEmail(entry.email)).length
          }
        />
      }
      onClose={onClose}
      open={open}
      primaryButton={{
        icon: <Icon.Send />,
        onClick: handleSubmit,
        text: 'Send',
      }}
      secondaryButton={{
        onClick: onClose,
        text: 'Cancel',
      }}
      size={ModalSize.Medium}
    >
      <Paragraph>Select a role for each email.</Paragraph>
      <NotificationList display="popup" />
      <Form>
        <Fields>
          <Inner>
            {inviteEntry.map((entry: InviteEntry, index: number) => (
              <Inner key={index}>
                {index !== 0 && <Line />}
                <Columns>
                  <FormElement>
                    <FormLabel>
                      {index === 0 ? (
                        <RoleHelpButton
                          icon={<Icon.CircleQuestionmark />}
                          iconRight
                          onClick={() => openHelpSidebar('Roles')}
                          variant="subtle"
                          size="small"
                          type="button"
                        >
                          Role
                        </RoleHelpButton>
                      ) : (
                        'Role'
                      )}
                    </FormLabel>
                    <Dropdown
                      placeholder="Select role"
                      onChange={(value: string) => {
                        setInviteEntry((s) =>
                          s.map((e: InviteEntry, i: number) => ({
                            email: e.email,
                            roleId: i === index ? value : e.roleId,
                          }))
                        );
                      }}
                      active={entry.roleId}
                      options={roles}
                      fullWidth
                    />
                  </FormElement>
                  <FormElement>
                    <FormLabel>Email</FormLabel>
                    <Email
                      value={entry.email}
                      onChange={(value: string) => {
                        setInviteEntry((s) =>
                          s.map((e: InviteEntry, i: number) => ({
                            email: i === index ? value : e.email,
                            roleId: e.roleId,
                          }))
                        );
                      }}
                      placeholder="Email address"
                      hasError={entry.email.length > 0 && !validateEmail(entry.email)}
                    >
                      {index !== 0 && <Remove onClick={(e: React.MouseEvent) => removeEmail(e, index)} />}
                    </Email>
                  </FormElement>
                </Columns>
              </Inner>
            ))}
            {inviteEntry.length < 5 && (
              <AddAnother size="small" type="button" icon={<Icon.Plus />} variant="subtle" onClick={handleAddEmail}>
                Add Another
              </AddAnother>
            )}
          </Inner>
        </Fields>
      </Form>
    </Modal>
  );
};
